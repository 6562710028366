@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;900&display=swap');


:root {
  --mainBlue: #4470F4;
  --mainBlack: #020918;
  --lightBlack: #707070;
  --greyText:#9496A1;
  --darkGray: #a0a0a0;
  --lightGray1: #f4f4f4;
  --lightGray2: #e0e0e0;
  --mainGreen: #0BC556;
  --darkBlue: #3B87B1;
  --mainRed: #ff0000;
  --mainWhite: #ffffff;
  --lightBlue:#E7EEFD;
  --textBlack:#3a3a3a;
  --secBlack:#393E59;
  --secWhite:#FAFAFC;
}


body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}


code {
  font-family: 'Inter', sans-serif;
}

/* @media (max-width:767px){
    body{
      padding: 0 16px;
    }
   } */
.allContent {
  padding: 20px 20px 0;
}

.padding-20 {
  padding: 10px;
}

.allContent60 {
  padding: 0 60px;
}

.blueShadeBtn {
  background: var(--mainBlue);
  border-radius: 4px;
  border: none;
  padding: 10px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  border: 1px solid var(--mainBlue);
}

.disableBtn {
  background: var(--lightGray2);
  border-radius: 4px;
  border: none;
  padding: 10px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--darkGray);
  border: 1px solid var(--lightGray2);
  cursor: no-drop !important;
}

.saveBtn {
  background: var(--mainBlue);
  border-radius: 4px;
  border: none;
  padding: 10px 30px;
  width: auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  border: 1px solid var(--mainBlue);
}

.whiteShadeBtn {
  background: var(--mainWhite);
  border: 1px solid var(--mainBlack);
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--mainBlack);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-decoration: none;
}

.hideDesk {
  display: none;
}

.padding8 {
  padding: 4px;
}

.paddingtop {
  padding-top: 20px;
}

.clearFilterBtn {
  font-family: 'Inter', sans-serif;
}

.scrollTable {
  overflow-x: auto;
}

@media (max-width:768px) {
  #filtersPannel {
    display: none;
  }

  .paddingtop {
    padding-top: 12px;
  }

  .allContent {
    padding: 12px 12px 0;
  }

  .hideMob {
    display: none;
  }

  .padding-20 {
    padding: 6px;
  }
}

.btnDark {
  background: var(--mainBlack);
  border-radius: 4px;
  color: #fff !important;
  padding: 10px 20px !important;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid var(--mainBlack);
}

.btnDisabled {
  background: var(--lightGray2);
  border-radius: 4px;
  color: var(--darkGray) !important;
  padding: 10px 20px !important;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid var(--lightGray2);
  cursor: not-allowed;

}

.bgGray {
  background-color: #e5e5e5;
}

.bgWhite {
  background-color: #fff;
}

.width-100 {
  width: 100%;
}

.navLink {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--lightBlack);
  text-decoration: none;
}

.navLink:hover {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--mainBlack);
  text-decoration: none;
}

.groupBox {
  display: flex;
  gap: 12px;
}


.w-auto {
  width: auto;
}

.mt-8 {
  margin-top: 8px;
}

/* Font Size */
.f-14 {
  font-size: 14px;
}

.darkBlue {
  color: #3B86B1;
}

.blue {
  color: var(--mainBlue) !important;
}

.red {
  color: var(--mainRed) !important;
}

.gray {
  color: var(--darkGray) !important;
}

/* End */

.capitalize {
  text-transform: lowercase;
}

select option{
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
}

.disabledBorder{
border: 1px solid var(--lightGray2) !important;
} 
.disabledColor{
color: var(--darkGray) !important;
}
.multi-range-slider .bar-left{
  padding: 0px !important;
}

.bg-lightBlue{
  background-color: var(--lightBlue) !important;
}

/* new */
.position-relative{
  position: relative;
}
.flex-row{
  display: flex;
  flex-direction: row;
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.flex-wrap{
  flex-wrap: wrap;
}
.blueText{
  color: var(--mainBlue);
}
.mainBlueBackground{
  background-color: var(--mainBlue);
}
.lightBlueBackground{
  background-color: var(--lightBlue);
}
.secWhiteBackground{
  background-color: var(--secWhite);
}
.mainWhiteBackground{
  background-color: var(--mainWhite);
}
.blueButton{
  font-size: 14px;
  font-weight: 700;
  border:1px solid var(--mainBlue);
  border-radius: 8px;
  color: var(--mainWhite);
  background-color: var(--mainBlue);
  padding: 12px 20px;
}
.transparentButton{
  font-size: 14px;
  font-weight: 700;
  border:1px solid #D2D4DA;
  border-radius: 8px;
  color: var(--mainBlack);
  background-color: var(--mainWhite);
  padding: 12px 20px;
}
.paddinglr-40{
  padding-left: 40px;
  padding-right: 40px;
}
.paddingtb-60{
  padding-top: 60px;
  padding-bottom: 60px;
}
.subText1{
font-size: 12px;
font-weight: 700;
letter-spacing: 0.08em;
text-align: center;
color: var(--mainBlue);

}
.subText2{
font-size: 28px;
font-weight: 700;
letter-spacing: 0em;
text-align: center;
color: var(--mainBlack);
margin-top:12px;
}
.cardContainer{
  margin-top: 40px;
  gap: 20px;

}
.contentCard{
  box-shadow: 0px 4px 40px 0px #4470F414;
  padding: 24px;
  width: fit-content;
  border-radius: 16px;
  background-color: var(--mainWhite);


}
.cardIcon{
  background-color: var(--lightBlue);
  padding: 10px 12px;
  border-radius: 8px;
  width: fit-content;
  margin-bottom: 20px;
}  
.cardSubText{
font-size: 14px;
font-weight: 400;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: var(--secBlack);

}
.cardMainText{

font-size: 14px;
font-weight: 700;
line-height: 17px;
margin-bottom: 12px;

text-align: left;
color: var(--mainBlue);
}

.whatsappShareButton{
  border: 1px solid var(--mainGreen);
  background-color: var(--mainWhite);
  border-radius: 8px;
  padding: 8px 20px;
font-size: 14px;
font-weight: 700;
color: var(--mainGreen);
svg{
  margin-right: 4px;
}
}
.ribbon-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  border-radius: 16px 0;
  left: 0;
}
.ribbon {
  padding: 8px 16px;
  font-family: Inter,sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: .04em;
  text-align: left;
  position: relative;
  background-color: #e52535;
  color: #fff;
}
.navArrowFlex span{
  padding: 12px;
    border: 1px solid var(--mainBlack);
    border-radius: 100%;
}
.navArrowFlex{
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width:768px) {
  .subText2{
    /* color: red !important; */
    font-size: 20px;
  }
  .paddinglr-40{
    padding-left: 16px;
    padding-right: 16px;
  }
}
